const bodyWrap = document.querySelector("body");
const menuToggle = document.querySelector(".site-menu-toggle");
const toggleImg = document.querySelector(".btn-toggle-img");
const toggleImgWrap = document.querySelector(".toggle-image");
const closeMenu = document.querySelector(".close-menu");
const searchToggle = document.querySelector(".search-toggle");
const searchWrap = document.querySelector(".search-overlay");
const hamburger = document.querySelector(".hamburger");
const menuMobile = document.querySelector(".mobile-nav-wrap");
const backTop = document.querySelector(".back-to-top");
const counterItem = document.querySelectorAll(".counter");
const setBackground = document.querySelectorAll("[setBackground]");
const sideNav = document.querySelector(".sidenav-group");
const inlineVideo = document.querySelectorAll(".inline-video");
const filterSelect = document.querySelector(".filter-address-select select");
const filterToggle = document.querySelector(".filter-toggle");
const closeFilter = document.querySelector(".close-filter");
const mobileFilter = document.querySelector(".mobile-filter");

try {
	Fancybox.bind("[data-fancybox]", {
		parentEl: document.querySelector("main"),
	});
} catch (error) {}
document.addEventListener("DOMContentLoaded", () => {
	hideElement();
	mappingFunc();
	swiperInit();
	generalFunction.init();
});

window.addEventListener("resize", function () {
	let width = this.window.innerWidth;
	if (width <= 1200.1) {
		hideElement();
		mappingFunc();
	}
});
window.addEventListener("scroll", function () {
	const width = this.window.innerWidth;
	if (width >= 1200.1) {
		if (
			document.body.scrollTop > 100 ||
			document.documentElement.scrollTop > 100
		) {
			bodyWrap.classList.add("minimize");
		} else {
			bodyWrap.classList.remove("minimize");
		}
	}
	const scrollTop = window.scrollY || document.documentElement.scrollTop;

	if (scrollTop > window.innerHeight) {
		sideNav.classList.add("show");
	} else {
		sideNav.classList.remove("show");
	}
});
document.addEventListener("click", (event) => {
	if (!event.target.closest(".search-toggle, .search-overlay")) {
		searchWrap.classList.remove("is-open");
	}
	if (!event.target.closest(".mobile-nav-wrap, .site-menu-toggle")) {
		menuMobile.classList.remove("is-open");
		bodyWrap.classList.remove("overlay-bg");
		hamburger.classList.remove("is-active");
	}
});

const generalFunction = {
	toggleFunc: () => {
		menuToggle.addEventListener("click", () => {
			menuMobile.classList.toggle("is-open");
			hamburger.classList.toggle("is-active");
			bodyWrap.classList.toggle("overlay-bg");
		});
		closeMenu.addEventListener("click", () => {
			menuMobile.classList.remove("is-open");
			hamburger.classList.remove("is-active");
			bodyWrap.classList.remove("overlay-bg");
		});
		searchToggle.addEventListener("click", () => {
			searchWrap.classList.toggle("is-open");
			bodyWrap.classList.toggle("overlay-bg");
		});
		if (toggleImg !== null) {
			toggleImg.addEventListener("click", () => {
				toggleImgWrap.classList.toggle("is-open");
			});
		}
		if (filterToggle !== null) {
			filterToggle.addEventListener("click", () => {
				mobileFilter.classList.add("is-open");
				hamburger.classList.remove("is-active");
				bodyWrap.classList.add("overlay-bg");
			});
			closeFilter.addEventListener("click", () => {
				mobileFilter.classList.remove("is-open");
				hamburger.classList.remove("is-active");
				bodyWrap.classList.remove("overlay-bg");
			});
		}

		inlineVideo.forEach(function (videoElement) {
			videoElement.addEventListener("click", function (event) {
				if (this.paused) {
					this.play();
					this.parentNode.classList.remove("pause");
				} else {
					this.pause();
					this.parentNode.classList.add("pause");
				}
			});
		});
		$(".toggle-wrap").each(function (index, element) {
			let $this = $(this);
			$this.addClass("toggle-instance-" + index);
			$(".toggle-instance-" + index + " .toggle-item").click(function () {
				if ($(this).hasClass("is-toggle")) {
					$(this).find(".article").slideUp();
					$(this).removeClass("is-toggle");
				} else {
					$(this).find(".article").slideDown();
					$(this).addClass("is-toggle");
				}
			});
		});

		if (filterSelect != null) {
			filterSelect.addEventListener("change", function () {
				let val = this.value;
				document
					.querySelectorAll(".filter-address-select .item")
					.forEach((item) => item.classList.remove("active"));
				document.getElementById(val).classList.add("active");
			});
		}
	},
	backTop: () => {
		backTop.addEventListener("click", (event) => {
			event.preventDefault();
			$("html, body").animate({ scrollTop: 0 }, "300");
		});
	},
	tabFunction: () => {
		$(".tab-nav a").on("click", function () {
			$(this).parents(".tab-nav").find("li").removeClass("active");
			$(this).parents("li").addClass("active");

			var tab = $(this).attr("data-type");
			$(this).parents("section").find(".tab-item").removeClass("active");
			$(this)
				.parents("section")
				.find("#" + tab)
				.addClass("active");
		});
	},
	dropdownMenu: () => {
		if ($(window).width() < 1200.1) {
			$(".drop-down .title em").on("click", function () {
				if ($(this).parents(".drop-down").hasClass("is-open")) {
					$(".drop-down .nav-sub").slideUp();
					$(".drop-down").removeClass("is-open");
				} else {
					$(".drop-down .nav-sub").slideUp();
					$(".drop-down").removeClass("is-open");
					$(this).parent().next().slideDown();
					$(this).parents(".drop-down").addClass("is-open");
				}
			});
			$(".tertiary-menu-toggle").on("click", function () {
				$(".nav-tertiary-menu").slideToggle();
			});
		}
	},

	counterAnimate: () => {
		if (counterItem !== null) {
			if (counterItem.length) {
				const counterUp = window.counterUp.default;
				const callback = (entries) => {
					entries.forEach((entry) => {
						const el = entry.target;
						if (
							entry.isIntersecting &&
							!el.classList.contains("is-visible")
						) {
							counterUp(el, {
								duration: 2000,
								delay: 200,
							});
							el.classList.add("is-visible");
						}
					});
				};
				const IO = new IntersectionObserver(callback, { threshold: 1 });

				counterItem.forEach((counter) => {
					IO.observe(counter);
				});
			}
		}
	},
	setBackground: () => {
		setBackground.forEach((attributes) => {
			attributes.style.cssText = `
			background-image: url(${attributes.getAttribute("setBackground")});
			background-size: cover;
			background-position: center;
		  `;
		});
	},

	a11yUser: () => {
		const wrapForms = document.querySelectorAll(".wrap-form");
		wrapForms.forEach(function (form) {
			const submitInput = form.querySelector("input[type='submit']");
			const labelFor = submitInput.getAttribute("name");
			const labelSubmit = form.querySelector(".frm-btnwrap .label");
			if (labelSubmit !== null) {
				labelSubmit.textContent = "Submit Button";
				labelSubmit.setAttribute("for", labelFor);
			}
		});
	},

	scrollToForm: () => {
		$(".btn-link-section").on("click", function (event) {
			if (this.hash !== "") {
				let offset = $("header").outerHeight();
				var hash = this.hash;
				$("html, body").animate(
					{
						scrollTop: $(hash).offset().top - offset,
					},
					800,
					function () {
						// window.location.hash = hash;
					}
				);
			} // End if
		});
	},
	appendHTML: () => {
		if ($("body").hasClass("homepage")) {
			if ($(".home-register-modal-wrap").length) {
				if ($(".home-register-modal").length) {
					$(".home-register-modal").appendTo(
						".home-register-modal-wrap"
					);
				}
			}
			setTimeout(() => {
				if ($(".fancybox__container").length) {
					$(".fancybox__container").appendTo("main");
				}
			}, 3000);
		}
		if ($("body").hasClass("news-detail-page")) {
			if ($(".news-category").length) {
				$(".news-category").appendTo(".news-detail-category");
			}
			
		}
	},

	figureImage: () => {
		$(".news-detail-page .fullcontent img").each(function (index, element) {
			let _this = $(element);
			let caption = _this.attr("title");
			if (caption) {
				let figure = $("<figure></figure>");
				_this.wrap(figure);

				let figcaption = $(`<figcaption>${caption}</figcaption>`);
				figcaption.insertAfter(_this);
			}
		});
	},

	init: () => {
		generalFunction.toggleFunc();
		generalFunction.backTop();
		generalFunction.tabFunction();
		generalFunction.dropdownMenu();
		generalFunction.counterAnimate();
		generalFunction.setBackground();
		generalFunction.appendHTML();
		generalFunction.scrollToForm();
		generalFunction.figureImage();
	},
};

function swiperInit() {
	var primarySwiper = new Swiper(".primary-banner .swiper", {
		// Optional parameters
		slidesPerView: 1,
		observer: true,
		observeParents: true,
		preventInteractionOnTransition: true,
		speed: 1205,
		autoplay: false,
		lazy: {
			loadPrevNext: true,
		},
		loop: true,
		navigation: {
			nextEl: ".primary-banner .nav-arrow-next",
			prevEl: ".primary-banner .nav-arrow-prev",
		},
		pagination: {
			el: ".primary-banner  .swiper-pagination",
			type: "bullets",
			clickable: true,
		},
	});

	$(".init-swiper .swiper").each(function (index) {
		var $this = $(this);
		$this.addClass("swiper-init-" + index);
		$this
			.parent()
			.find(".prev")
			.addClass("prev-nav-" + index);
		$this
			.parent()
			.find(".next")
			.addClass("next-nav-" + index);
		$this
			.parent()
			.find(".swiper-pagination")
			.addClass("pagination-inst-" + index);

		var swiper = new Swiper(".swiper-init-" + index, {
			loop: false,
			observer: true,
			observeParents: true,
			speed: 1200,
			autoplay: {
				delay: 4000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
	
			slidesPerView: "auto",
			navigation: {
				nextEl: ".next-nav-" + index,
				prevEl: ".prev-nav-" + index,
			},
			pagination: {
				el: ".pagination-inst-" + index,
				type: "bullets",
				clickable: true,
				dynamicBullets: true,
				dynamicMainBullets: 1,
			},
		});
	});
	var swiperThumb = new Swiper(".swiper-thumb .swiper", {
		spaceBetween: 10,
		breakpointsInverse: true,
		breakpoints: {
			200: {
				slidesPerView: 3,
			},
			576: {
				slidesPerView: 4,
			},
			769: {
				slidesPerView: 5,
			},
			1360: {
				slidesPerView: 4,
				spaceBetween: 32,
			},
		},
		freeMode: true,
		watchSlidesVisibility: true,
		watchSlidesProgress: true,
	});

	var swiperMain = new Swiper(".swiper-main .swiper", {
		// Optional parameters
		spaceBetween: 30,
		slidesPerView: 1,
		speed: 1205,
		breakpointsInverse: true,
		autoplay: {
			delay: 3500,
		},
		thumbs: {
			swiper: swiperThumb,
		},
		pagination: {
			el: ".swiper-pagination",
			type: "fraction",
		},

		navigation: {
			nextEl: ".swiper-thumb .next",
			prevEl: ".swiper-thumb .prev",
		},
	});
	var swiperGrid = new Swiper(".about-grid-swiper .swiper", {
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		lazy: {
			loadPrevNext: true,
		},
		observer: true,
		spaceBetween: 30,
		observeParents: true,

		breakpoints: {
			200: {
				slidesPerView: 1,
			},
			460: {
				slidesPerView: 2,
			},
			768: {
				slidesPerView: 3,
			},
			1200: {
				slidesPerView: 3,
				grid: {
					rows: 2,
					fill: "row",
				},
			},
		},

		navigation: {
			nextEl: ".about-grid-swiper .next",
			prevEl: ".about-grid-swiper .prev",
		},
		pagination: {
			el: ".about-grid-swiper .swiper-pagination",
			clickable: true,
		},
	});
	var swiperGrid = new Swiper(".grid-swiper .swiper", {
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		lazy: {
			loadPrevNext: true,
		},
		observer: true,
		spaceBetween: 30,
		observeParents: true,

		breakpoints: {
			200: {
				slidesPerView: 1,
			},
			576: {
				slidesPerView: 2,
			},
			1200: {
				slidesPerView: 2,
				grid: {
					rows: 2,
					fill: "row",
				},
			},
		},

		navigation: {
			nextEl: ".grid-swiper .next",
			prevEl: ".grid-swiper .prev",
		},
	});

	var historySwiper = new Swiper(".swiper-history .swiper", {
		autoplay: {
			delay: 3000,
		},
		// Optional parameters
		slidesPerView: 3,
		spaceBetween: 0,
		slideToClickedSlide: true,
		breakpoints: {
			200: {
				slidesPerView: 1,
			},
			420: {
				slidesPerView: 2,
			},
			576: {
				slidesPerView: 3,
			},
			1024: {
				slidesPerView: 5,
			},
			1200: {
				slidesPerView: 5,
			},
			1440: {
				slidesPerView: 6,
			},
		},
		observer: true,
		observeParents: true,
		preventInteractionOnTransition: true,
		speed: 1205,
		lazy: {
			loadPrevNext: true,
		},
		navigation: {
			nextEl: ".swiper-history .next",
			prevEl: ".swiper-history .prev",
		},
	});
}
function hideElement() {
	if ($(window).width() < 1200.1) {
		const [btn, search, menu, topNav] = [
			".header-admissions-wrap",
			".searchbox",
			".nav-primary-menu",
			".site-top-nav",
		].map((selector) => document.querySelector(selector));

		[btn, search, menu, topNav].forEach(
			(el) => (el.style.display = "flex")
		);
	}
}

function mappingFunc() {
	new MappingListener({
		selector: ".search-overlay",
		mobileWrapper: ".mobile-nav-wrap",
		mobileMethod: "appendTo",
		desktopWrapper: ".search-toggle",
		desktopMethod: "insertAfter",
		breakpoint: 1200.1,
	}).watch();

	new MappingListener({
		selector: ".nav-primary-menu",
		mobileWrapper: ".mobile-nav-wrap",
		mobileMethod: "appendTo",
		desktopWrapper: ".header-top",
		desktopMethod: "insertAfter",
		breakpoint: 1200.1,
	}).watch();
	new MappingListener({
		selector: ".site-top-nav",
		mobileWrapper: ".mobile-nav-wrap",
		mobileMethod: "appendTo",
		desktopWrapper: ".language-wrap",
		desktopMethod: "insertBefore",
		breakpoint: 1200.1,
	}).watch();

	new MappingListener({
		selector: ".header-admissions-wrap",
		mobileWrapper: ".mobile-nav-wrap",
		mobileMethod: "appendTo",
		desktopWrapper: ".site-menu-toggle",
		desktopMethod: "insertBefore",
		breakpoint: 1200.1,
	}).watch();
}
